import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/css";
import "swiper/css/scrollbar";

import "../../pages/index.scss";
import "./bloglist.scss";

import { Scrollbar } from "swiper";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Bloglist = () => (
  <div className="tz-bloglist">
    <div className="container">
      <h2 data-sal="fade" data-sal-duration="1000">
        Novinky <FontAwesomeIcon icon={faArrowRight} />
      </h2>

      <>
        <Swiper
          data-sal="fade"
          data-sal-duration="1000"
          slidesPerView={"auto"}
          spaceBetween={20}
          modules={[Scrollbar]}
          effect={"fade"}
          scrollbar={{
            hide: true,
          }}
          className="tz-bloglist__swiper"
          breakpoints={{
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          <SwiperSlide className="tz-bloglist__item">
            <Link
              className="tz-bloglist__link"
              to="/novinky/2024-12-13-registrace-na-novy-tanecni-kurz-otevrena"
            >
              <span className="tz-bloglist__date">2024-12-13</span>
              <h3 className="tz-bloglist__title">
                Registrace na nový kurz otevřena
              </h3>
              <p className="tz-bloglist__description">
                <strong>Od 29. ledna 2025 začínáme další taneční kurz.</strong>{" "}
                Opět každou středu ve&nbsp;velkém společenském sále
                KC&nbsp;Zlonín. Registrujte se již dnes.
              </p>
              <div className="tz-button tz-button--small tz-bloglist__button">
                detail
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="tz-bloglist__item">
            <Link
              className="tz-bloglist__link"
              to="/novinky/2024-08-12-tanecni-kurzy-pro-deti-ve-zlonine"
            >
              <span className="tz-bloglist__date">2024-08-12</span>
              <h3 className="tz-bloglist__title">
                Taneční kurzy pro&nbsp;děti startují
              </h3>
              <p className="tz-bloglist__description">
                <strong>Ve středu 4. září 2024</strong> začínáme taneční kurzy
                pro&nbsp;děti ve&nbsp;Zloníně.
                <br />
                <br />
              </p>
              <div className="tz-button tz-button--small tz-bloglist__button">
                detail
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="tz-bloglist__item">
            <Link
              className="tz-bloglist__link"
              to="/novinky/2024-08-10-pozvanka-na-kurz-latin-fever"
            >
              <span className="tz-bloglist__date">2024-08-10</span>
              <h3 className="tz-bloglist__title">Latin Fever od září 2024</h3>
              <p className="tz-bloglist__description">
                <strong>Startujeme 8. září 2024 Latin Fever.</strong> Opět
                každou neděli ve&nbsp;velkém společenském sále KC&nbsp;Zlonín.
                Registrujte se již dnes.
              </p>
              <div className="tz-button tz-button--small tz-bloglist__button">
                detail
              </div>
            </Link>
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  </div>
);

export default Bloglist;
